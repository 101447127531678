import * as API from "@/api/common";
import { Boot } from "@wangeditor/editor";

Boot.registerMenu({
  key: "custom-undo",
  factory() {
    return {
      title: "撤销",
      tag: "button",
      iconSvg: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M5.828 7l2.536 2.536L6.95 10.95 2 6l4.95-4.95 1.414 1.414L5.828 5H13a8 8 0 1 1 0 16H4v-2h9a6 6 0 1 0 0-12H5.828z"/></svg>`,
      isActive: () => false,
      getValue: () => "",
      isDisabled: () => false,
      exec: editor => editor.undo()
    };
  }
});

Boot.registerMenu({
  key: "custom-redo",
  factory() {
    return {
      title: "重做",
      tag: "button",
      iconSvg: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M18.172 7H11a6 6 0 1 0 0 12h9v2h-9a8 8 0 1 1 0-16h7.172l-2.536-2.536L17.05 1.05 22 6l-4.95 4.95-1.414-1.414L18.172 7z"/></svg>`,
      isActive: () => false,
      getValue: () => "",
      isDisabled: () => false,
      exec: editor => editor.redo()
    };
  }
});

export const toolbarConfig = {
  toolbarKeys: [
    "custom-undo",
    "custom-redo",
    "clearStyle",
    "|",
    "headerSelect",
    "fontFamily",
    "fontSize",
    "lineHeight",
    "bold",
    "italic",
    "underline",
    "color",
    "bgColor",
    "|",
    "numberedList",
    "blockquote",
    "indent",
    "delIndent",
    "divider",
    {
      key: "group-justify",
      title: "对齐",
      iconSvg: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M3 4h18v2H3V4zm0 15h14v2H3v-2zm0-5h18v2H3v-2zm0-5h14v2H3V9z"/></svg>`,
      menuKeys: [
        "justifyLeft",
        "justifyRight",
        "justifyCenter",
        "justifyJustify"
      ]
    },
    "insertLink",
    {
      key: "group-image",
      iconSvg: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21 15v3h3v2h-3v3h-2v-3h-3v-2h3v-3h2zm.008-12c.548 0 .992.445.992.993v9.349A5.99 5.99 0 0 0 20 13V5H4l.001 14 9.292-9.293a.999.999 0 0 1 1.32-.084l.093.085 3.546 3.55a6.003 6.003 0 0 0-3.91 7.743L2.992 21A.993.993 0 0 1 2 20.007V3.993A1 1 0 0 1 2.992 3h18.016zM8 7a2 2 0 1 1 0 4 2 2 0 0 1 0-4z"/></svg>`,
      menuKeys: ["insertImage", "uploadImage"]
    }
  ]
};

export const editorConfig = {
  placeholder: "请输入...",
  scroll: false,
  MENU_CONF: {
    uploadImage: {
      async customUpload(file, insert) {
        const { data } = await API.uploadFile(file);
        insert(data.url, file.name, "");
      }
    },
    insertLink: {
      parseLinkUrl(url) {
        url = String(url).trim();
        if (!url.startsWith("http")) {
          url = "https://" + url;
        }
        return url;
      }
    }
  }
};
