<template>
  <a-modal
    width="80vw"
    centered
    :footer="null"
    :mask-closable="false"
    :closable="false"
    :keyboard="false"
  >
    <Toolbar
      class="toolbar-wrapper"
      :editor="editor"
      :default-config="config.toolbarConfig"
    />
    <div class="editor-wrapper">
      <Editor
        v-model="options.html"
        :defaultConfig="config.editorConfig"
        @onCreated="onEditorCreated"
        @click.native="onEditorClick"
      />
    </div>
  </a-modal>
</template>

<script>
import { DomEditor } from "@wangeditor/editor";
import { Toolbar, Editor } from "@wangeditor/editor-for-vue";
import * as Interface from "./interface";
import * as config from "./assembly.config";
import * as utils from "./assembly.utils";

export default {
  components: { Toolbar, Editor },
  props: {
    defaultWidget: Object,
    $resolve: Function,
    $reject: Function
  },
  data() {
    return {
      config,
      editor: null,
      options: new Interface.IWidgetOptions(this.defaultWidget.options)
    };
  },
  destroyed() {
    this.editor.destroy();
  },
  methods: {
    async onEditorCreated(editor) {
      this.editor = Object.seal(editor);
      await this.$nextTick();
      const toolbar = DomEditor.getToolbar(editor);
      toolbar.$toolbar.append(
        utils.createDivider(),
        utils.createMenu({
          title: "取消",
          iconSvg: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"/></svg>`,
          onClick: this.$reject
        }),
        utils.createMenu({
          title: "保存",
          iconSvg: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M18 19h1V6.828L17.172 5H16v4H7V5H5v14h1v-7h12v7zM4 3h14l2.707 2.707a1 1 0 0 1 .293.707V20a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm4 11v5h8v-5H8z"/></svg>`,
          onClick: this.onSave
        })
      );
    },
    onEditorClick() {
      if (this.editor) {
        this.editor.focus();
      }
    },
    onSave() {
      this.defaultWidget.options = this.options;
      this.$resolve(this.options);
    }
  }
};
</script>

<style lang="less" scoped>
@import "~@wangeditor/editor/dist/css/style.css";
@import "../../assembly/vars.less";
@import "./overwrite.less";

.ant-modal-root {
  .overwrite_modal();
  .overwrite_toolbar();
  .overwrite_editor();
  /deep/ .ant-modal-body {
    display: flex;
    flex-direction: column;
  }
}

.toolbar-wrapper {
  flex-shrink: 0;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.12);
  z-index: 1;
}
.editor-wrapper {
  flex: 1 1 0;
  overflow: auto;
  background: #f3f5f7;
  border-radius: 0 0 4px 4px;
  /deep/ div[data-w-e-textarea] {
    max-width: 850px;
    box-shadow: rgba(0, 0, 0, 0.06) 0px 0px 10px 0px,
      rgba(0, 0, 0, 0.04) 0px 0px 0px 1px;
    background: #fff;
    margin: 80px auto;
    padding: 90px;
  }
  /deep/ div[data-slate-editor] {
    height: auto;
    min-height: 850px;
  }
  @media screen and (max-width: 1330px) {
    /deep/ div[data-w-e-textarea] {
      margin: 20px auto;
    }
  }
  @media screen and (max-width: 1100px) {
    /deep/ div[data-w-e-textarea] {
      margin: 0 auto;
      padding: 30px;
    }
  }
}
</style>
