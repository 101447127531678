/**
 * @typedef {{
 *  title: string,
 *  iconSvg: string,
 *  onClick: (evt: Event) => any
 * }} Menu
 * @param {Menu} opts
 */
export function createMenu(opts) {
  const div = document.createElement("div");
  const button = document.createElement("button");
  div.classList.add("w-e-bar-item");
  button.classList.add("w-e-menu-tooltip-v5");
  button.dataset.tooltip = opts.title;
  button.innerHTML = opts.iconSvg;
  button.addEventListener("click", opts.onClick);
  div.append(button);
  return div;
}

export function createDivider() {
  const div = document.createElement("div");
  div.classList.add("w-e-bar-divider");
  return div;
}
